<template>
  <div>
    <b-row>
      <b-col md="8">
        <conta
          :utilizador="user"
          :perfis="userProfile"
        />
      </b-col>
      <b-col md="4">
        <permissoes
          :utilizador="user"
          :perfis="userProfile"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import permissoes from './Permissoes.vue'
import conta from './Conta.vue'

export default {
  components: {
    BRow, BCol, permissoes, conta,
  },
  data() {
    return {
      user: {
        id: '',
        nome: '',
        username: '',
        email: '',
        password: '',
        ativo: '',
      },
      userProfile: {
        idUtilizador: '',
        profiles: [],
      },
    }
  },
}
</script>
