<template>
  <b-card
    no-body
    class="border mt-1"
  >
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        <span class="align-middle ml-50">Permissões</span>
      </b-card-title>
    </b-card-header>
    <div>
      <b-table
        small
        :fields="fields"
        :items="itemsProfiles"
        responsive="sm"
      >
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(code)="data">
          {{ data.value }}
        </template>

        <!-- A virtual composite column -->
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell(id)="data">
          <b-form-checkbox
            v-model="perfis.profiles"
            :value="data.item.id"
            :checked="data.item.id"
          />

        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    utilizador: Object,
    perfis: Object,
  },
  data() {
    return {
      itemsProfiles: [],
      fields: [
        {
          key: 'codigo',
          label: 'Código',
          sortable: true,
        },
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: true,
        },
        {
          key: 'id',
          label: 'Ativo',
          sortable: true,
        },
      ],
    }
  },
  created() {
    axios.get('/api/v1/profiles/')
      .then(res => {
        this.itemsProfiles = res.data
      })
  },

}

</script>

<style>

</style>
