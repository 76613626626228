<template>
  <b-card title="Arquivo">

    <!-- User Info: Input Fields -->
    <b-form>
      <validation-observer ref="form">
        <b-row>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="username"
                    v-model="utilizador.username"
                    type="text"
                    maxlength="20"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalUtilizadores"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Email -->
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="utilizador.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Nome"
              label-for="nome"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="nome"
                  v-model="utilizador.nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Role -->

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="utilizador.password"
                  :type="passwordFieldTypeNew"
                  name="new-password"
                  placeholder="Password"
                  maxlength="70"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Repetir Password"
            >

              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  placeholder="Password"
                  maxlength="70"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!--/ retype password -->
        </b-row>
        <b-row>
          <!-- Field: Status -->
          <b-col
            md="4"
          >
            <b-form-group
              label="Ativo"
              label-for="user-status"
            >
              <validation-provider
                #default="{ errors }"
                name="ativo"
                rules="required"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="utilizador.ativo"
                    name="some-radios"
                    value="1"
                  >
                    Ativo
                  </b-form-radio>
                  <b-form-radio
                    v-model="utilizador.ativo"
                    name="some-radios"
                    value="0"
                  >
                    Inativo
                  </b-form-radio>

                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mt-2"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer></b-form>
    <b-modal
      ref="modalUtilizadores"
      hide-footer
      size="xl"
    >
      <selecionarUtilizador @utilizadorSelecionado="selectUtilizador" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BCard, BForm, BInputGroup, BInputGroupPrepend, BButton, BFormRadio, BModal, BInputGroupAppend,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import selecionarUtilizador from '../../../Listagens/Utilizadores/SelecionarUtilizador.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BModal,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    selecionarUtilizador,
  },
  directives: {
    Ripple,
  },

  props: {
    utilizador: Object,
    perfis: Object,
  },
  data() {
    return {
      required,
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    localize('pt', pt)
  },

  methods: {
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveUser()
        }
      })
    },

    getPerfilUtilizadores(userId) {
      axios.get(`/api/v1/userProfiles/user/${userId}`)
        .then(res => {
          this.perfis.profiles = []
          this.perfis.idUtilizador = userId
          for (let i = 0; i < res.data.length; i += 1) {
            this.perfis.profiles.push(res.data[i].idPerfil)
          }
        })

      this.$forceUpdate()
    },

    saveUser() {
      if (this.utilizador.password === this.RetypePassword) {
        this.guardar()
      } else {
        this.passwordDiferentes('danger', 'CheckIcon')
      }
    },

    guardar() {
      this.utilizador.blocked = this.utilizador.active
      axios.put(`/api/v1/users/id/${this.utilizador.id}`, this.utilizador)
        .then(res => {
          this.perfis.idUtilizador = this.utilizador.id
          this.saveUserProfile()
          this.sucesso('sucess', 'CheckIcon')
          this.$forceUpdate()
        })
    },
    duplicado(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'Email já existe !',
          variant,
        },

      })
    },
    passwordDiferentes(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'Passwords Diferente !',
          variant,
        },

      })
    },
    sucesso(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'sucesso',
          icon,
          text: 'Sucesso !',
          variant,
        },

      })
    },
    saveUserProfile() {
      axios.post('/api/v1/userProfiles/profiles', this.perfis)
        .then(
          this.sucesso('success', 'CheckIcon'),
        )
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    showModalUtilizadores() {
      this.$forceUpdate()
      this.$refs.modalUtilizadores.show()
    },
    hideModalUtilizadores() {
      this.$refs.modalUtilizadores.hide()
    },
    selectUtilizador(row) {
      this.utilizador.id = row.id
      this.utilizador.nome = row.nome
      this.utilizador.username = row.username
      this.utilizador.email = row.email
      this.utilizador.ativo = row.ativo
      this.utilizador.password = ''
      this.RetypePassword = ''
      this.getPerfilUtilizadores(this.utilizador.id)
      this.hideModalUtilizadores()
    },

    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
