var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Arquivo"}},[_c('b-form',[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"username","type":"text","maxlength":"20"},model:{value:(_vm.utilizador.username),callback:function ($$v) {_vm.$set(_vm.utilizador, "username", $$v)},expression:"utilizador.username"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalUtilizadores}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","maxlength":"256"},model:{value:(_vm.utilizador.email),callback:function ($$v) {_vm.$set(_vm.utilizador, "email", $$v)},expression:"utilizador.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","maxlength":"100"},model:{value:(_vm.utilizador.nome),callback:function ($$v) {_vm.$set(_vm.utilizador, "nome", $$v)},expression:"utilizador.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-new-password","label":"Password"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":"Password","maxlength":"70"},model:{value:(_vm.utilizador.password),callback:function ($$v) {_vm.$set(_vm.utilizador, "password", $$v)},expression:"utilizador.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":"Repetir Password"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":"Password","maxlength":"70"},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ativo","label-for":"user-status"}},[_c('validation-provider',{attrs:{"name":"ativo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"1"},model:{value:(_vm.utilizador.ativo),callback:function ($$v) {_vm.$set(_vm.utilizador, "ativo", $$v)},expression:"utilizador.ativo"}},[_vm._v(" Ativo ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"0"},model:{value:(_vm.utilizador.ativo),callback:function ($$v) {_vm.$set(_vm.utilizador, "ativo", $$v)},expression:"utilizador.ativo"}},[_vm._v(" Inativo ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.switchLoc()}}},[_vm._v(" Guardar ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('b-modal',{ref:"modalUtilizadores",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarUtilizador',{on:{"utilizadorSelecionado":_vm.selectUtilizador}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }